import React, { useEffect, useState } from "react";
import "./operatorGuide.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Incident,
  ResourceInteractionSource,
  SiteFloorPlan,
  toDateString,
  useI18n,
} from "compass-commons";
import { ShellConfig } from "@msicie/public-types";
import {
  RESOURCE_INTERACTION_EVENT,
  ResourceInteractionPayload,
  ResourceInteractionEvent,
} from "og-interaction";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosRounded";
import { performTaskExecution } from "../../helpers/taskHelpers";
import OperatorGuideHeader from "./header/OperatorGuideHeader";
import OperatorGuideExecution from "./execution/OperatorGuideExecution";
import OperatorGuideFooter from "./footer/OperatorGuideFooter";
import OperatorGuideService from "../../services/OperatorGuideService";
import { OGExecution } from "../../model/OG/OGExecution";
import { useGlobalContext } from "../../contexts/GlobalContext";
import LoadingExecutionResponseError from "../../errors/LoadingExecutionResponseError";
import { AssignedIncidentResponse } from "../../model/incident/AssignedIncidentResponse";
import { ResourceCommandAdditionalInfo } from "../../model/resource/ResourceCommand";
import MapService from "../../services/MapService";
import { ResourceMapping } from "../../model/resource/ResourceMapping";
import ResourceMappingService from "../../services/ResourceMappingService";

interface OperatorGuideProps {
  assignedIncidentResponse?: AssignedIncidentResponse;
  shellConfig?: ShellConfig;
}

const GENERIC_INCIDENT_TYPE_ICON_PATH =
  "incident-icon-images/incident-type/Generic.svg";

const OperatorGuide = (props: OperatorGuideProps): JSX.Element => {
  const COULD_NOT_LOAD = "Could not load operator guide";

  const { assignedIncidentResponse, shellConfig } = props;
  const { stateService } = useGlobalContext();
  const { t: translate } = useI18n();
  const { currentOgExecution, alertSubject } = stateService;
  const [ogExecution, setOgExecution] = useState<OGExecution>(
    currentOgExecution.value
  );
  const [incident, setIncident] = useState<Incident>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [ogExecutionHolder, setOgExecutionHolder] = useState<OGExecution>(null);

  const [resourceCommandAdditionalInfo, setResourceCommandAdditionalInfo] =
    useState<ResourceCommandAdditionalInfo>(null);

  async function loadInitialElements() {
    let floorPlanName = null;
    try {
      const deviceFloorPlans =
        !assignedIncidentResponse.resourceMappingId &&
        assignedIncidentResponse.floorPlanId
          ? ([
              await MapService.getFloorPlanById(
                assignedIncidentResponse.siteId,
                assignedIncidentResponse.floorPlanId
              ),
            ] as SiteFloorPlan[]) // On-demand incident floorplan
          : await MapService.getFloorPlanByDeviceId(
              assignedIncidentResponse.siteId,
              assignedIncidentResponse.resourceMappingId
            ); // Default incident floorplans

      if (deviceFloorPlans?.length > 0) {
        floorPlanName = deviceFloorPlans[0].name;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const iconRelativePath = assignedIncidentResponse.incidentType
        ? assignedIncidentResponse.incidentType.iconPath
        : GENERIC_INCIDENT_TYPE_ICON_PATH;

      const incidentRes = {
        priority: assignedIncidentResponse.priority,
        icon: iconRelativePath,
        location: assignedIncidentResponse.siteId,
        id: assignedIncidentResponse.incidentId,
        type: assignedIncidentResponse.incidentDescription,
        triggerDeviceId: assignedIncidentResponse.resourceMappingId,
        creationTimestamp: toDateString(
          assignedIncidentResponse.incidentTimestamp
        ),
      } as Incident;
      setIncident(incidentRes);

      setResourceCommandAdditionalInfo({
        sourceName:
          assignedIncidentResponse.triggerType === "ON-DEMAND"
            ? assignedIncidentResponse.triggerType
            : assignedIncidentResponse.resourceMappingName,
        siteName: assignedIncidentResponse.siteName,
        behaviorName: assignedIncidentResponse.incidentDescription,
        sourceFloorPlanName: floorPlanName,
      });

      if (assignedIncidentResponse?.operatorGuideExecutionId) {
        const executionResult = await OperatorGuideService.getExecutionById(
          assignedIncidentResponse.operatorGuideExecutionId
        );
        if (executionResult) {
          setOgExecution(executionResult);
          currentOgExecution.next(executionResult);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      throw new LoadingExecutionResponseError(error);
    }
  }

  useEffect(() => {
    if (assignedIncidentResponse) {
      setLoading(true);
      loadInitialElements().then();
    }
  }, [assignedIncidentResponse]);

  const scroll = () => {
    const section = document.querySelector("#scroll-to");
    section?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    const currentOgExecutionSubs = currentOgExecution.subscribe(
      (val: OGExecution) => {
        if (val) {
          setOgExecutionHolder(null);
          setOgExecutionHolder(val);
        }
      }
    );

    return function cleanup() {
      currentOgExecutionSubs.unsubscribe();
    };
  }, [currentOgExecution]);

  useEffect(() => {
    if (ogExecutionHolder) {
      setOgExecution(ogExecutionHolder);
      setTimeout(() => {
        scroll();
      }, 100);
    }
  }, [ogExecutionHolder]);

  async function handleSocInteractionResourceEvent(
    payload: ResourceInteractionPayload
  ) {
    try {
      const rm: ResourceMapping = await ResourceMappingService.getResourceById(
        payload.deviceId
      );
      performTaskExecution(
        stateService,
        null,
        ogExecution.registryResponse.id,
        rm,
        payload.functionalityId,
        ResourceInteractionSource.SITE_LAYOUT
      );
    } catch (error) {
      alertSubject.next({
        title: translate(error, {
          resourceName: payload.deviceName,
        }),
      });
    }
  }

  useEffect(() => {
    let sub = null;
    if (ogExecution) {
      sub = shellConfig?.eventBus.event$?.subscribe((event) => {
        if (event.type === RESOURCE_INTERACTION_EVENT) {
          const { payload } = event as ResourceInteractionEvent;
          handleSocInteractionResourceEvent(payload);
        }
      });
    }

    return () => {
      sub?.unsubscribe();
    };
  }, [ogExecution]);

  const getLoadingOrDefault = (): JSX.Element => {
    return (
      <div className="operator-guide-default-div">
        {loading ? (
          <CircularProgress />
        ) : (
          <span>
            {translate(COULD_NOT_LOAD, { ns: "Operation", value: 10 })}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      id="operator-guide"
      className={`compass-rounded-corner operator-guide ${
        collapsed && "operator-guide-collapsed"
      }`}
    >
      {incident && ogExecution ? (
        <>
          <OperatorGuideHeader
            incidentHeaderDescription={{
              code: incident.code,
              description: incident.type,
              iconPath: incident.icon,
              id: incident.id,
              incidentTimestamp: incident.creationTimestamp,
              priority: incident.priority,
              resourceMappingName: assignedIncidentResponse.resourceMappingName,
              siteName: assignedIncidentResponse.siteName,
              triggerType: assignedIncidentResponse.triggerType,
            }}
            className="operator-guide-header-container"
          />

          <OperatorGuideExecution
            execution={ogExecution}
            resourceCommandAdditionalInfo={resourceCommandAdditionalInfo}
            incident={incident}
          />
          <OperatorGuideFooter
            incident={incident}
            hasActiveTask={
              ogExecution.engineTaskResponseDTO?.taskTypeEngineList?.length > 0
            }
            executionId={ogExecution.registryResponse.id}
          />
        </>
      ) : (
        <>{getLoadingOrDefault()}</>
      )}
      {isDMS && (
        <div className="operator-collapse-icon">
          {collapsed ? (
            <ArrowBackIosIcon
              sx={{ fontSize: "16px" }}
              onClick={() => setCollapsed(!collapsed)}
            />
          ) : (
            <ArrowForwardIosIcon
              sx={{ fontSize: "16px" }}
              onClick={() => setCollapsed(!collapsed)}
            />
          )}
        </div>
      )}
    </div>
  );
};

OperatorGuide.defaultProps = {
  assignedIncidentResponse: null,
  shellConfig: null,
};

export default OperatorGuide;
