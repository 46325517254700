import DatabaseSearchSelectedOptionDTORegistryResponse from "../databaseSearch/DatabaseSearchSelectedOptionDTORegistryResponse";
import { ResourceMapping } from "../resource/ResourceMapping";

export interface OGExecutionTaskBlockLink {
  id: string;
  text: string;
  linkNextTask: string;
}

// eslint-disable-next-line no-shadow
export enum TagContext {
  Generic = "GENERIC",
  Resources = "RESOURCES",
}

export interface OGExecutionTaskBlock {
  id: string;
  userId: string;
  name: string;
  type: string;
  payload: string;
  actionable: boolean;
  options: OGExecutionTaskBlockLink[];
  positiveAnswer: OGExecutionTaskBlockLink;
  negativeAnswer: OGExecutionTaskBlockLink;
  button: OGExecutionTaskBlockLink;
  tags: string[];
  context: TagContext;
  resourceMappingList: ResourceMapping[];
  searchable: boolean;
  selectedOption: DatabaseSearchSelectedOptionDTORegistryResponse;
}
