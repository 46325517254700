import React, { useState } from "react";
import { TextField, RadioButton, RadioGroup } from "dms-lib";
import { useI18n } from "compass-commons";
import FormDialog from "../../../commons/dialog/FormDialog";
import { COMMENTS_MAXIMUM_CHARACTERS } from "../../../../utils/constants";

export interface ClassificationResult {
  selected: string;
  comment: string;
}

interface ClassifyIncidentProps {
  openDialog: boolean;
  // eslint-disable-next-line no-shadow
  selectionCallback: (ClassificationResult) => void;
  isOGCompleted?: boolean;
  showNotImplementedFeatures?: boolean;
}

const ClassifyIncident = (props: ClassifyIncidentProps): JSX.Element => {
  const {
    openDialog,
    selectionCallback,
    isOGCompleted,
    showNotImplementedFeatures,
  } = props;

  const { t: translate } = useI18n();

  const [classification, setClassification] = useState({
    selected: null,
    comment: null,
  } as ClassificationResult);

  /** Static texts block */
  const OPTION_DRILL_TEXT = "Drill";
  const OPTION_FALSE_ALARM_TEXT = "False alarm";
  const OPTION_FALSE_ALARM_STAFF_TEXT = "False alarm | Staff";
  const OPTION_FALSE_ALARM_TECHNICAL_TEXT = "False alarm | Technical";
  const OPTION_REAL_ALARM_TEXT = "Real alarm";
  const OPTION_TEST_TEXT = "Test";
  const CLASSIFY_INCIDENT_TITLE_TEXT =
    "Before you go, please classify this incident:";
  /** Static text block ends */

  const handleChangeRadioGroup = (value: string) => {
    setClassification({
      ...classification,
      selected: value,
    });
  };

  const dialogCallback = (isConfirmed: boolean) => {
    if (isConfirmed) {
      selectionCallback(classification);
    }
    selectionCallback(null);
  };

  const getOptions = () => {
    const optionsArr = [
      OPTION_DRILL_TEXT,
      OPTION_FALSE_ALARM_TEXT,
      OPTION_FALSE_ALARM_STAFF_TEXT,
      OPTION_FALSE_ALARM_TECHNICAL_TEXT,
      OPTION_REAL_ALARM_TEXT,
      OPTION_TEST_TEXT,
    ];

    const options = [];

    optionsArr.forEach((op: string, index) =>
      options.push(
        <RadioButton
          key={index}
          value={op}
          style={{ fontWeight: "bold" }}
          label={op}
        />
      )
    );
    return options;
  };

  const onChangeHandler = (event) => {
    const comment =
      event.target.value?.trim().length > 0
        ? event.target.value.substring(0, COMMENTS_MAXIMUM_CHARACTERS - 1)
        : null;
    setClassification((prev) => {
      return {
        ...prev,
        comment,
      };
    });
  };

  return (
    <FormDialog
      isDialogOpen={openDialog}
      dialogCallback={dialogCallback}
      disapprovalText={translate("cancel", { ns: "Shared" })}
      approvalText={translate("incident.clearBtn")}
      approvalConditionsMet={!!classification?.comment}
      content={
        <div className="classification-content">
          {!isOGCompleted && (
            <div className="unfinished-og" data-cr="classify-incident">
              <div>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {translate("incident.operatorGuideNotCompeted")}
                </span>
              </div>
              <div>
                <span>
                  {translate("incident.operatorGuideNotCompetedReasonQuestion")}
                </span>
              </div>
              <TextField
                value={classification.comment}
                placeholder={translate("incident.commentInputPlaceholder")}
                onChange={onChangeHandler}
                autoFocus
              />
            </div>
          )}
          {showNotImplementedFeatures && (
            <>
              <div style={{ marginTop: "10px" }}>
                <span>{CLASSIFY_INCIDENT_TITLE_TEXT}</span>
              </div>
              <div className="classification">
                <RadioGroup
                  value={classification.selected}
                  onChange={(value) => handleChangeRadioGroup(value)}
                >
                  {...getOptions()}
                </RadioGroup>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};
ClassifyIncident.defaultProps = {
  isOGCompleted: false,
  showNotImplementedFeatures: false,
};
export default ClassifyIncident;
