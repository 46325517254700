import { capitalizeFirstLetter } from "compass-commons";
import React from "react";
import { Button } from "dms-lib";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";
import { OGExecutionTaskBlock } from "../../../../../model/OG/OGExecutionTaskBlock";
import DatabaseSearchResult from "../../../../../model/databaseSearch/internal/DatabaseSearchResult";

interface DatabaseSearchActionProps {
  taskState: TaskStepState;
  blockType: OGExecutionTaskBlock;
  actionCallBack: (
    eventId: string,
    selectedOption: DatabaseSearchResult
  ) => void;
  selectedOption: DatabaseSearchResult;
}

const DatabaseSearchAction = (props: DatabaseSearchActionProps) => {
  const { taskState, blockType, actionCallBack, selectedOption } = props;
  const eventId = blockType.button?.id;
  return (
    <div
      className={taskState}
      style={{ float: "right" }}
      data-cr="perform-action"
    >
      <Button
        dataCr="search-task-button"
        color="primary"
        variant="contained"
        onClick={() => {
          actionCallBack(eventId, selectedOption);
        }}
        size="small"
        disabled={
          TaskStepState.COMPLETED === taskState ||
          (TaskStepState.CURRENT === taskState && !selectedOption)
        }
      >
        <span>
          {blockType.button?.text
            ? capitalizeFirstLetter(blockType.button?.text)
            : ""}
        </span>
      </Button>
    </div>
  );
};

export default DatabaseSearchAction;
