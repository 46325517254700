import DatabaseSearchResultDTO from "../../../../../../model/databaseSearch/DatabaseSearchResultDTO";
import DatabaseSearchSelectedOptionDTO from "../../../../../../model/databaseSearch/DatabaseSearchSelectedOptionDTO";
import DatabaseSearchResult from "../../../../../../model/databaseSearch/internal/DatabaseSearchResult";
import { OptionType } from "../../../../../../model/databaseSearch/internal/OptionType";
import {
  ImageLoadState,
  SearchImageBase,
} from "../../../../../../model/databaseSearch/internal/SearchImageBase";
import {
  InfoFieldDTO,
  InfoFieldType,
} from "../../../../../../model/OG/InfoFieldDTO";

const extractTextToDisplayFromSearchResult = (
  searchResult: DatabaseSearchResultDTO
): string => {
  // TODO : This logic will change in the future, when Integrations send a field named textToDisplay or have an info field type for that
  const textToDisplay = searchResult.infoFields.find(
    (infoField) => infoField.type === InfoFieldType.TEXT
  ).value;

  return textToDisplay || searchResult.id;
};

const setImagesInitialState = (
  infoFields: InfoFieldDTO[]
): SearchImageBase[] => {
  return infoFields
    .filter((infoField) => infoField.type === InfoFieldType.PHOTO_ID)
    .map((infoField) => {
      return {
        imageId: infoField.value,
        imageData: null,
        state: ImageLoadState.LOAD_NOT_STARTED,
      };
    });
};

export const mapSearchResultDtoToInternalSearchResult = (
  databaseSearchResult: DatabaseSearchResultDTO,
  subsystemId: string,
  subsystemName: string
): DatabaseSearchResult => {
  const searchResultInformation: DatabaseSearchResult = {
    type: OptionType.DEFAULT,
    subsystemId,
    subsystemName,
    id: databaseSearchResult.id,
    infoFields: databaseSearchResult.infoFields,
    textToDisplay: extractTextToDisplayFromSearchResult(databaseSearchResult),
    images: setImagesInitialState(databaseSearchResult.infoFields),
  };

  return searchResultInformation;
};

export const mapSelectedOptionDtoToInternalSelectedOption = (
  selectedOptionDTO: DatabaseSearchSelectedOptionDTO
): DatabaseSearchResult => {
  const internalSelectedOption: DatabaseSearchResult = {
    type: selectedOptionDTO.type,
    subsystemId: selectedOptionDTO.subsystemId,
    subsystemName: selectedOptionDTO.subsystemName,
    id: selectedOptionDTO.id,
    infoFields: selectedOptionDTO.infoFields,
    images: selectedOptionDTO.images,
    textToDisplay: "",
  };

  return internalSelectedOption;
};

export const mapInternalSelectedOptionToSelectedOptionDto = (
  selectedOption: DatabaseSearchResult
): DatabaseSearchSelectedOptionDTO => {
  const selectedOptionDTO: DatabaseSearchSelectedOptionDTO = {
    type: selectedOption.type,
    subsystemId: selectedOption.subsystemId,
    subsystemName: selectedOption.subsystemName,
    id: selectedOption.id,
    infoFields: selectedOption.infoFields,
    images: selectedOption.images,
  };

  return selectedOptionDTO;
};
